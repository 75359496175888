// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
import { showSuccessToast } from 'utils/showSuccessToast';
import { TagsStateProps } from 'types/tags';

export const url = process.env.REACT_APP_API_URL + '/api/configuration/v1/tags';

const initialState: TagsStateProps = {
  tags: []
};

export const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    // SET TAGS
    setTags(state, action) {
      state.tags = action.payload;
    }
  }
});

export function getTags() {
  return async () => {
    try {
      const response = await axios.get(url);
      dispatch(tags.actions.setTags(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function editTag(tagId: string, name: string, type: string) {
  return async () => {
    try {
      await axios.put(`${url}/${tagId}`, { name, type });
      showSuccessToast('Tag editada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addTags(name: string, type: string) {
  return async () => {
    try {
      await axios.post(url, { name, type });
      showSuccessToast('Tag adicionada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteTag(tagId: string) {
  return async () => {
    try {
      await axios.delete(`${url}/${tagId}`);
      showSuccessToast('Tag removida com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default tags.reducer;
