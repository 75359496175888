import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

export const showSuccessToast = (Message: string) => {
  dispatch(
    openSnackbar({
      open: true,
      message: Message,
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: true
    })
  );
};
