// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      <svg
        version="1.1"
        viewBox="0 0 2048 753"
        width="200"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform="translate(254,4)"
          d="m0 0h2l14 19 10 13 14 19 16 21 14 19 16 21 14 19 16 21 14 19 16 21 14 19 16 21 7 9h2l12-11 10-9 15-14 10-9 12-11 13-12 10-9 16-15 12-11 4 1 16 11 43 30 32 22 20 14 8 5-1 3-117 22-14 3-15 57-15 56-3 10-21 12-52 30-28 16-26 15-22 13-2 7-10 60-14 87-1 9-3-3-10-35-21-75-3-11 1-7 58-145-6 8-11 16-16 23-8 11-4 1-36-24-38-25-117-77-2-11-4-3 1-4 2-5-1-14-7 4-6-9-5-9 1-4 13-8-2-18-4-5 4-12h-2v3l-5-2-10-6h-7l-6 1-2-9v-5l12-2 2 6v3h2l2-6 4-6 5 2 2 1-2-10v-5h-3l-2 6-2 2-11-6-5-3 2-5 7-12 11 5v-9l-8-74 4 1 25 15 29 17 25 15 32 19 15 9 32 19 15 9 32 19 28 17 24 14 32 19 25 15 7 4-10-9-13-12-8-7-15-14-8-7-13-12-11-10-8-7-13-12-11-10-8-7-15-14-8-7-2-6-16-90z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(1220,456)"
          d="m0 0h49l1 1v290h-54l-10-13-12-17-39-54-10-14-52-72-10-14-13-18-2-3-1 204-1 1h-50l-1-2v-288h54l13 18 14 19 13 18 10 14 26 36 14 19 10 14 26 36 14 19 7 10 2 3 1-206z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(1422,455)"
          d="m0 0 53 1 21 3 18 5 16 7 13 8 13 10 13 13 12 17 10 21 5 17 4 19 1 11v26l-2 16-5 21-6 17-8 15-10 14-9 10-8 8-14 10-18 10-16 6-16 4-14 2-17 1h-113v-288l2-1 26-2zm-22 45-2 1v203h52l21-2 19-5 16-8 14-12 11-16 7-18 4-18 1-11v-26l-2-13-4-15-6-15-9-14-10-11-18-12-13-5-16-3z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(774,457)"
          d="m0 0h173v45l-59 1h-61v74h104v45l-103 1-1 78h129l1 1v44l-1 1h-182z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(626,452)"
          d="m0 0h25l25 3 23 6 18 7 1 1v12l-3 37-2 4-18-10-19-8-21-5-25-1-14 3-9 6-7 9-4 13v8l3 9 6 8 7 7 20 12 20 11 19 12 21 14 13 12 8 10 6 13 2 8 1 9v10l-2 15-5 14-10 16-13 13-14 9-15 7-18 5-15 2h-30l-27-5-20-6-17-7 1-13 5-35 1-4 6 2 21 11 16 6 17 4h26l13-4 10-6 8-9 3-7 1-5v-9l-4-10-4-5-8-7-13-8-26-14-22-13-17-12-10-9-8-8-8-12-5-14-2-12v-11l3-18 5-13 9-14 10-11 16-11 16-7 18-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(1891,451)"
          d="m0 0h26l20 3 16 5 16 7 15 9 13 11 14 14 10 14 9 16 8 19 6 25 2 17v22l-4 26-6 20-8 18-10 16-7 9-9 10-8 8-15 11-16 9-14 6-19 5-16 2h-25l-24-5-15-5-21-11-11-8-14-12-12-14-10-15-8-16-7-19-4-17-2-14v-29l3-20 6-21 7-16 7-13 10-14 11-12 9-9 15-11 18-10 16-6 19-4zm0 30-18 4-12 5-16 10-7 7-8 7-11 16-8 16-5 14-4 17-2 26 2 23 5 21 7 17 7 13 9 12 14 14 18 11 12 5 13 3 8 1h19l16-3 13-5 9-5 14-10 13-13 11-17 8-18 4-13 3-14 1-12v-23l-2-16-5-19-9-21-9-14-9-10-9-9-17-11-12-5-11-3-6-1z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(1661,457)"
          d="m0 0h34v289l-1 1h-33l-1-2v-287z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(10,180)"
          d="m0 0 11 6 5 3-8 17-5-2-12-7 2-6 6-10z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(18,136)"
          d="m0 0 5 2 12 6-2 6-6 12-16-8 1-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(52,264)"
          d="m0 0 5 1 7 4-5 11-6-2-5-3 1-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(70,238)"
          d="m0 0 5 1 6 3v5l-4 7-6-2-5-3 3-9z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(67,82)"
          d="m0 0 3 1 1 10-10 1-2-10z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(93,194)"
          d="m0 0 6 2 3 2-2 5-2 3-4-1-4-2 1-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(48,227)"
          d="m0 0 7 4-1 4-2 5-5-2-4-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(50,116)"
          d="m0 0 5 2 3 2-2 5-2 3-5-2-3-2 2-6z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(24,247)"
          d="m0 0 6 1 3 2-1 5-4 4-6-4 1-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(61,143)"
          d="m0 0 6 2 3 3-5 7-5-2-2-2 1-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(77,104)"
          d="m0 0 5 1 4 4-4 6-5-1-3-2 1-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(104,281)"
          d="m0 0 5 2 1 3-3 4-5-2-1-2z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(85,233)"
          d="m0 0 6 2-1 5-3 3-4-3 1-6z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(41,170)"
          d="m0 0 5 2 1 4-2 3-5-1-1-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(38,202)"
          d="m0 0 6 2-1 5-1 2-4-1-2-1 1-6z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(98,266)"
          d="m0 0 6 3-2 5-3 1-4-3 2-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(74,212)"
          d="m0 0 5 2 1 2-3 5-5-2v-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(64,190)"
          d="m0 0 4 1v5l-5 2-2-3v-4z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(80,281)"
          d="m0 0h3l1 7h-6l-1-5z"
          fill={theme.palette.primary.main}
        />
        <path
          transform="translate(18,224)"
          d="m0 0h3l1 3-1 2-4-1z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </>
  );
};

export default LogoMain;
