import { useState } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Snackbar from 'components/@extended/Snackbar';
// import Customization from 'components/Customization';
import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import DisconnectedModal from 'components/DisconnectedModal';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import ProfilePage from 'layout/MainLayout/Header/HeaderContent/Profile';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'q;
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== ////
const App = () => {
  const [loading] = useState<boolean>(false);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <Notistack>
                <Routes />
                <ProfilePage />
                <Snackbar />
                <DisconnectedModal />
              </Notistack>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
