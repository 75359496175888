export interface ChannelStateProps {
  status: Status[];
}

export interface Status {
  cellPhone: string;
  chatBotId: string;
  contactIntegration: string;
  createdAt: string;
  deactivatedAt2: string;
  name: string;
  operatorId: string;
  operatorName: string;
  remoteId: string;
  status: string;
  updatedAt: string;
  _id: string;
  image: string;
  qrCode?: string;
}

export enum EnumStatus {
  Disconnected = 'DISCONNECTED',
  Connected = 'CONNECTED',
  WaitingQrCode = 'WAITING_QRCODE'
}
