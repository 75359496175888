/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Switch } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { dispatch, useSelector } from 'store';
import { changeNotificationSettings } from 'store/reducers/chat';

const NotificationSettings = () => {
  const authUser = useSelector((state) => state.auth.user);
  const [firstLoad, setFirstLoad] = useState(true);
  const [inAttendanceMail] = useState(false);
  const [waitingAttendanceMail] = useState(false);
  const [inAttendancePopup, setInAttendancePopup] = useState<boolean>(false);
  const [inAttendanceSound, setInAttendanceSound] = useState<boolean>(false);
  const [waitingAttendancePopup, setWaitingAttendancePopup] = useState<boolean>(false);
  const [waitingAttendanceSound, setWaitingAttendanceSound] = useState<boolean>(false);

  // const toggleInAttendanceMail = () => setInAttendanceMail((prev) => !prev);
  const toggleInAttendancePopup = () => setInAttendancePopup((prev) => !prev);
  const toggleInAttendanceSound = () => setInAttendanceSound((prev) => !prev);
  // const toggleWaitingAttendanceMail = () => setWaitingAttendanceMail((prev) => !prev);
  const toggleWaitingAttendancePopup = () => setWaitingAttendancePopup((prev) => !prev);
  const toggleWaitingAttendanceSound = () => setWaitingAttendanceSound((prev) => !prev);

  const changeNotificationSettins = async () => {
    if (authUser) {
      dispatch(
        changeNotificationSettings(
          inAttendanceMail,
          inAttendancePopup,
          inAttendanceSound,
          waitingAttendanceMail,
          waitingAttendancePopup,
          waitingAttendanceSound
        )
      );
    }
  };

  useEffect(() => {
    if (authUser && firstLoad) {
      setInAttendancePopup(authUser.settings.notification.inAttendancePopup ?? false);
      setInAttendanceSound(authUser.settings.notification.inAttendanceSound ?? false);
      setWaitingAttendancePopup(authUser.settings.notification.waitingAttendancePopup ?? false);
      setWaitingAttendanceSound(authUser.settings.notification.waitingAttendanceSound ?? false);
      setFirstLoad(false);
    }
  }, [authUser]);

  useEffect(() => {
    if (!firstLoad) {
      changeNotificationSettins();
    }
  }, [inAttendancePopup, inAttendanceSound, waitingAttendancePopup, waitingAttendanceSound]);

  return (
    <Box
      sx={{
        mt: 0,
        padding: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        borderTop: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }}
    >
      <SimpleBar
        style={{
          maxHeight: '200px'
        }}
      >
        <Stack>
          <Box>
            <Typography fontWeight="bold">Em atendimento</Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={inAttendanceSound}
                onChange={() => {
                  toggleInAttendanceSound();
                }}
              />
              <Typography variant="h6">Aviso sonoro</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={inAttendancePopup}
                onChange={() => {
                  toggleInAttendancePopup();
                }}
              />
              <Typography variant="h6">Aviso janela pop-up</Typography>
            </Stack>
          </Box>
          <Box>
            <Typography fontWeight="bold">Aguardando atendimento</Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={waitingAttendanceSound}
                onChange={() => {
                  toggleWaitingAttendanceSound();
                }}
              />
              <Typography variant="h6">Aviso sonoro</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={waitingAttendancePopup}
                onChange={() => {
                  toggleWaitingAttendancePopup();
                }}
              />
              <Typography variant="h6">Aviso janela pop-up</Typography>
            </Stack>
          </Box>
        </Stack>
      </SimpleBar>
    </Box>
  );
};

export default NotificationSettings;
