// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
import { ChannelStateProps, EnumStatus, Status } from 'types/channel';
import { showSuccessToast } from 'utils/showSuccessToast';

export const url = process.env.REACT_APP_API_URL;

const initialState: ChannelStateProps = {
  status: []
};

export const channel = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    // SET STATUS CHANNELS
    setStatus(state, action) {
      const updatedStatuses = action.payload;

      updatedStatuses.forEach((newStatus: Status) => {
        const index = state.status.findIndex((status) => status.chatBotId === newStatus.chatBotId);

        if (index !== -1) {
          state.status[index] = { ...state.status[index], ...newStatus };
          if (newStatus?.status === EnumStatus.Disconnected || newStatus?.status === EnumStatus.Connected) {
            state.status[index].qrCode = undefined;
          }
        } else {
          state.status.push(newStatus);
        }
      });
    },

    // SET QR CODE FOR SPECIFIC STATUS
    setQrCode(state, action) {
      const newStatus = action.payload;
      const index = state.status.findIndex((status) => status.chatBotId === newStatus.chatBotId);

      if (index !== -1) {
        state.status[index].qrCode = newStatus.qrCode;
      }
    }
  }
});

export function getStatus() {
  return async () => {
    try {
      const response = await axios.get(`${url}/api/chatbot/v1/status`);

      return dispatch(channel.actions.setStatus(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function startStatus(channelId: string) {
  return async () => {
    try {
      await axios.post(`${url}/api/chatbot/v1/start`, { channelId });
      showSuccessToast('Canal conectado com sucesso, aguardando leitura do QrCode.');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function stopStatus(channelId: string) {
  return async () => {
    try {
      await axios.post(`${url}/api/chatbot/v1/stop`, { channelId });
      showSuccessToast('Canal desconectado com sucesso.');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default channel.reducer;
export const { setStatus, setQrCode } = channel.actions;
