import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

// types
import { AuthProps, Notification } from 'types/auth';
import { chat } from './chat';
import { getItemLocalStorage } from 'hooks/useLocalStorage';

const token = getItemLocalStorage('accessToken');
const url = process.env.REACT_APP_API_URL + '/api/user/v1';
// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| SLICE - AUTH ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRegister(state, action: PayloadAction<{ user: AuthProps['user'] }>) {
      state.user = action.payload.user;
    },
    setLogin(state, action: PayloadAction<{ user: AuthProps['user'] }>) {
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = action.payload.user;
    },
    setLogout(state) {
      state.isInitialized = true;
      state.isLoggedIn = false;
      state.user = null;
    },
    updateNotificationSettingsAuth(state, action: PayloadAction<Notification>) {
      if (state.user) {
        state.user.settings = { ...state.user.settings, ...action.payload };
      }
    }
  }
});

export default auth.reducer;

export function getLoggedUsers() {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/logged-users`, {
        headers: {
          'x-access-token': `${token}`
        }
      });
      dispatch(auth.actions.updateNotificationSettingsAuth(response.data.settings));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export const { setRegister, setLogin, setLogout, updateNotificationSettingsAuth } = auth.actions;
