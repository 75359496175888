/* eslint-disable @typescript-eslint/no-unused-vars */
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container } from '@mui/material';

// project-imports
import Drawer from './Drawer';
// import Header from './Header';
import HorizontalBar from './Drawer/HorizontalBar';

import { DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';

// types
import { MenuOrientation } from 'types/config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // // set media wise responsive drawer
  // useEffect(() => {
  //   if (!miniDrawer) {
  //     dispatch(openDrawer(!downXL));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [downXL]);

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh' }}>
      {/* <Header /> */}
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box
        component="main"
        sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 2, md: 3 } }}
      >
        {/* <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit', mb: isHorizontal ? 2 : 'inherit' }} /> */}
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            height: '100%',
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* <Breadcrumbs
            sx={{ paddingLeft: '3px' }}
            navigation={navigation}
            titleBottom
            card={false}
            divider={false}
          /> */}
          <Outlet />
          {/* <Footer /> */}
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
