// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
// import { showSuccessToast } from 'utils/showSuccessToast';
import { CampaignDataEdit, CampaingsStateProps } from 'types/campaings';
import { showSuccessToast } from 'utils/showSuccessToast';

export const url = process.env.REACT_APP_API_URL + '/api/marketing/v1';

const initialState: CampaingsStateProps = {
  campaigns: [],
  metaCampaings: {
    pages: 0,
    rows: 0
  },
  campaignsTypes: []
};

export const campaigns = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setMetaCampaings(state, action) {
      state.metaCampaings = action.payload;
    },
    setCampaignsTypes(state, action) {
      state.campaignsTypes = action.payload;
    }
  }
});

export function getCampaigns(filter = '', page = 1, orderField = 'dateFrom') {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaigns`, {
        params: {
          filter,
          page,
          order_field: orderField
        }
      });

      dispatch(campaigns.actions.setCampaigns(response.data.data));
      dispatch(campaigns.actions.setMetaCampaings(response.data.meta));

      return;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCampaignsById(campaignId: string) {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaigns/${campaignId}`);
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCampaignsTypes() {
  return async () => {
    try {
      const response = await axios.get(`${url}/campaign-types`);

      return dispatch(campaigns.actions.setCampaignsTypes(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function editCampaigns(campaign: CampaignDataEdit) {
  return async () => {
    try {
      await axios.put(`${url}/campaigns/${campaign._id}`, {
        channels: campaign.channels,
        content: campaign.content,
        dateFrom: campaign.dateFrom,
        description: campaign.description,
        groups: campaign.groups,
        mediaType: campaign.mediaType,
        name: campaign.name,
        types: campaign.types
      });
      showSuccessToast('Campanha editada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function removeCampaign(campaignId: string) {
  return async () => {
    try {
      const response = await axios.delete(`${url}/campaigns/${campaignId}`);

      return dispatch(campaigns.actions.setCampaignsTypes(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default campaigns.reducer;
export const { setCampaigns } = campaigns.actions;
