import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Drawer, useMediaQuery } from '@mui/material';

// project-imports
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';

import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { ArrowRight2 } from 'iconsax-react';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  window?: () => Window;
}

const MainDrawer = ({ window }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen } = useSelector((state) => state.menu);

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={drawerOpen} />, [drawerOpen]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}
      aria-label="mailbox folders"
    >
      {!downLG ? (
        <MiniDrawerStyled
          variant="permanent"
          open={drawerOpen}
        >
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit'
            }
          }}
        >
          {drawerHeader}
          {drawerContent}
        </Drawer>
      )}
      <ButtonBase
        sx={{
          borderRadius: 0,
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
          top: '5%',
          position: 'fixed',
          left: drawerOpen ? `${DRAWER_WIDTH - 1}px` : downLG ? 0 : `${MINI_DRAWER_WIDTH - 1}px`,
          transition: 'left 0.2s ease',
          zIndex: 1000,
          boxShadow: theme.customShadows.z1,
          bgcolor: downLG ? '#fff' : 'transparent',
          border: `4px solid ${downLG ? '#fff' : 'transparent'}`,
          borderRight: 'none',
          p: 0.25
        }}
        aria-label="open profile"
        aria-controls={drawerOpen ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={() => dispatch(openDrawer(!drawerOpen))}
      >
        <ArrowRight2
          style={{
            transform: drawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease'
          }}
        />
      </ButtonBase>
    </Box>
  );
};

export default MainDrawer;
